<template>
    <div class="comp">
        <div class="left_tit">
            <div class="gong_name">
                <div class="borderDiv"></div>
                <div class="titDiv">
                    <p class="name_p">公司介绍</p>
                    <p class="eng_p">COMPANY INTRODUCTION</p>
                </div>
            </div>
            <div class="ints">
                <div class="jieshao">
                    陕西悠诚信息科技有限公司是一家托管企业构建高效管理平台，提供技术服务、整合营销及运营服务，促进家教互动、帮助全社会培养全能一流人才的综合服务企业
                </div>
                <div class="boe_red"></div>
            </div>
            <div class="englis">
                <div class="eng_jie">
                    Shaanxi Youcheng Information Technology Co., Ltd. is a comprehensive service enterprise that builds
                    an efficient management platform, provides technical services, integrated marketing and operation
                    services, promotes tutor interaction and helps the whole society cultivate all-round first-class
                    talents
                </div>
                <div class="cric">
                    <img src="../../assets/imgs/icon.png" alt="">
                </div>
            </div>
        </div>
        <div class="right_img">
            <img src="../../assets/imgs/gongsi.png" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.comp {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    .left_tit {
        width: 38%;
        height: 100%;
        padding: 0 70px 0 200px;
        // background: red;
        .gong_name {
            position: relative;
            width: 266px;
            height: 108px;
            margin-top: 74px;
            .borderDiv {
                width: 208px;
                height: 108px;
                border-radius: 4px;
                border: 10px solid #f0f0f0;
                position: absolute;
                left: 60px;
                top: -30px;
                z-index: -1;
            }
            .titDiv {
                text-align: left;
                .name_p {
                    color: #2e2133;
                    font-size: 36px;
                    font-weight: 600;
                }
                .eng_p {
                    color: #5c6366;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .ints {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 50px;
            font-size: 16px;
            font-weight: normal;
            color: #454a4d;
            .boe_red {
                width: 100px;
                height: 3px;
                background: #f30b0b;
                margin-top: 15px;
            }
        }
        .englis {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 30px;
            font-size: 16px;
            font-weight: normal;
            color: #8a9499;
            .cric {
                width: 100px;
                height: 20px;
                margin-top: 15px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .right_img {
        width: 45%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>